<template>
  <div class="CollaborateurDashboard">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow v-if="missions.length > 3">
      <CCol>
        <h1> Votre tableau de bord </h1>
      </CCol>
    </CRow>

    <CRow v-else>
      <CCol sm="6">
        <h1> Votre tableau de bord </h1>
      </CCol>
      <CCol sm="6" class="text-right">
        <router-link to="aide/presentation">❓ <strong>Aide</strong> - Premiers pas avec Eclerk ?</router-link>
      </CCol>
    </CRow>

    <CRow class="font-dosis mt-3">
      <CCol sm="4">
        <CWidgetIcon
          text="missions en cours"
          color="primary"
          :icon-padding="false"
        >
          <strong>{{missions.length}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol sm="4">
        <CWidgetIcon
          text="actions majeures en cours"
          color="warning"
          :icon-padding="false"
        >
          <strong>{{nbMajeuresPriorities}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol sm="4">
        <CWidgetIcon
          text="actions mineures en cours"
          color="warning"
          :icon-padding="false"
        >
          <strong>{{nbMineuresPriorities}}</strong>
        </CWidgetIcon>
      </CCol>
    </CRow>

    <CCard >
      <CCardHeader>
        <strong>Eclerk - Votre cabinet</strong>
      </CCardHeader>
      <CCardBody>
        <div v-if="userGroupContains(['BOSS'])">
          <CRow>
            <CCol md="6">
              <span style="font-family: Dosis, sans-serif; font-size: 1.1rem;">Votre entreprise</span>
              <p class="mt-2">
                <span v-if="statistics.has_logo" ><CIcon class="text-success" name="cil-check-circle" /> <strong>Votre logo</strong> a bien été chargé</span>
                <span v-else><CIcon class="text-danger" name="cil-x-circle" />
                  Vous n'avez pas encore chargé <strong>le logo</strong> de votre entreprise,
                  <router-link to="/company" target="_blank">cliquez ici </router-link> pour le charger.
                </span>
                <br>
                <span v-if="statistics.has_bank_coordinates"><CIcon class="text-success" name="cil-check-circle" /> <strong>Les informations bancaires</strong> de votre cabinet ont bien été renseignées</span>
                <span v-else><CIcon class="text-danger" name="cil-x-circle" />
                  Vous n'avez pas encore renseigné les <strong>informations bancaires de votre cabinet</strong>. Celle-ci sont utilisées lors de l'édition automatique de vos factures.
                  <router-link to="/company" target="_blank">cliquez ici </router-link> pour renseigner ces informations
                </span>
              </p>
            </CCol>
            <CCol md="6" class="text-md-right text-left">
              <span style="font-family: Dosis, sans-serif; font-size: 1.1rem;">Paiements en ligne</span>
              <div v-if="account.id == ''">
                <CButton class="mt-2" color="outline-primary" @click="createConnectedAccount"> 💰 Configurer votre compte pour accepter des paiements en ligne</CButton>
              </div>
              <div v-else-if="!account.charges_enabled ">
                <CButton color="outline-primary" @click="createConnectedAccountOnboardingLink">Finaliser la configuration de votre compte</CButton>
              </div>
              <div v-if="account.id != '' && account.charges_enabled && !account.payouts_enabled">
                <CIcon clas="text-success" name="cil-check-circle" /> <strong>Votre compte a bien été configuré.</strong> <br>
                Vous pouvez désormais recevoir des paiements en ligne via Eclerk ! 💰 <br>
                <CIcon class="text-danger" name="cil-x-circle" /> Vous ne pouvez pas pour l'instant virer l'argent reçu vers votre compte
                <CButton color="ghost-primary" size="sm" @click="getExpressDashboardLoginLink">Compléter et vérifier les informations de mon compte</CButton>
              </div>
              <div v-if="account.id != '' && account.charges_enabled && account.payouts_enabled">
                <CIcon clas="text-success" name="cil-check-circle" /> <strong>Votre compte a bien été configuré.</strong> <br>
                Vous pouvez désormais recevoir des paiements en ligne via Eclerk et virer l'argent sur votre compte ! 💰 <br>
                <p class="mt-1">
                  <CButton color="ghost-primary" size="sm" @click="getExpressDashboardLoginLink">J'accède au tableau de bord de mon compte</CButton>
                </p>
              </div>
            </CCol>
          </CRow>
        </div>

        <div v-else>
          <CRow>
            <CCol>
              <span style="font-family: Dosis, sans-serif; font-size: 1.1rem;">Paiements en ligne</span>
              <div v-if="account.id != '' && account.charges_enabled">
                <CIcon clas="text-success" name="cil-check-circle" /> <strong>Votre compte a bien été configuré.</strong> <br>Vous pouvez désormais recevoir des paiements en ligne via Eclerk ! 💰
              </div>
              <div v-else>
                <CIcon clas="text-danger" name="cil-x-circle" /> <strong>Votre compte n'est pas encore configuré.</strong> <br>Vous ne pouvez pas encore recevoir des paiements en ligne via Eclerk. Veuillez
                contacter le responsable de votre abonnement Eclerk si vous souhaitez configurer le compte de votre cabinet.
              </div>
            </CCol>
          </CRow>
        </div>

        <CRow class="mt-3" v-if="userGroupContains(['MANAGER'])">
          <CCol>
            <p>
              <span style="text-transform: uppercase; font-family: Dosis, sans-serif; font-size: 1.1rem;">💥 Nouveauté 💥</span><br>
              Générez et envoyez vos <strong> devis </strong> directement via Eclerk ! <br>
              Retrouvez tous vos devis en
              <router-link
                :to="'/manager-dashboard-devis/'">
                cliquant ici
              </router-link> ou en vous rendant dans la rubrique <i>Devis, facture, paiement</i> puis
              en cliquant sur <i>Devis</i>
            </p>
          </CCol>
        </CRow>




        <div class="mt-3">
          <span style="font-family: Dosis, sans-serif; font-size: 1.1rem;">Votre activité</span><br>
          <p class="mt-2">
            <span v-if="statistics.nb_mission_types > 0">Vous êtes affecté à <strong>{{missionsRender.length}} dossiers</strong> en cours <br></span>
            <span v-else-if="userGroupContains(['ADMIN'])">
              <span><CIcon class="text-danger" name="cil-x-circle" />
                Veuillez renseigner <strong>les types de dossiers</strong> que prend en charge votre cabinet en
                <router-link to="/administration/manage-mission-types" target="_blank">cliquant ici.</router-link>
              </span>
              <br>
            </span>

            <span v-if="userGroupContains(['ADMIN']) && (companyStripeProductId === stripeSmallProductId || companyStripeProductId === stripeMediumProductId)">
              <span v-if="statistics.nb_collabs > 1">Votre cabinet compte <strong>{{statistics.nb_collabs}} collaborateurs actifs</strong></span>
              <span v-else ><CIcon class="text-danger" name="cil-x-circle" />
                Vous pouvez rajouter <strong>des collaborateurs</strong> en
                <router-link to="/administration/manage-collaborateurs" target="_blank">cliquant ici.</router-link>
              </span>
              <br>
            </span>

            <span v-if="statistics.nb_clients > 0"><strong>{{statistics.nb_clients}} clients</strong> actifs</span>
            <span v-else><CIcon class="text-danger" name="cil-x-circle" />
              Vous n'avez pas encore renseigné <strong>de clients</strong>. Vous pouvez renseigner autant de clients que vous le souhaitez en
              <router-link to="/add-new-client" target="_blank">cliquant ici.</router-link>
            </span>

            <br>

            <span v-if="statistics.nb_contacts > 0"><strong>{{statistics.nb_contacts}} contacts</strong> actifs</span>
            <span v-else><CIcon class="text-danger" name="cil-x-circle" />
              Vous n'avez pas encore renseigné <strong>de contacts</strong> (personne physique chez votre client ou prospect en cours).
              Vous pouvez renseigner autant de contacts que vous le souhaitez en
              <router-link to="/add-new-contact" target="_blank">cliquant ici.</router-link>
            </span>



          </p>
        </div>

      </CCardBody>
    </CCard>

    <CCard >
      <CCardHeader>
        <strong>Vos prochaines échéances</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="cursor-table"
          hover
          :items="prioritiesRender"
          :fields="prioritiesRenderFields"
          @row-clicked="goToPriorityPage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucune action ne vous a été attribuée" }'
        >
          <template #ending_date="{item}">
            <td>
             <span>{{$dayjs(item.ending_date).format('DD/MM/YYYY')}}</span>
            </td>
          </template>

          <template #priority_state="{item}">
            <td>
              <CBadge v-if="item.priority_state == 'MAJ'" color="danger">
                Majeure
              </CBadge>
              <CBadge v-else color="secondary">
                Mineure
              </CBadge>
            </td>
          </template>

        </CDataTable>
      </CCardBody>
    </CCard>


    <CCard >
      <CCardHeader>
        <strong>Vos missions en cours</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="cursor-table"
          hover
          :items="missionsRender"
          :fields="missionsRenderFields"
          @row-clicked="goToMissionPage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
          <template #is_firm="{item}">
            <td>
              <CIcon v-if="item.is_firm === true" name="cil-building"/>
              <CIcon v-else name="cil-user"/>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { stripeSoloProduct, stripeSmallProduct, stripeMediumProduct } from '@/variables/localVariables'
import userMixins from '@/mixins/userMixins'
import { APIUserConnected } from '@/api/APIUserConnected'
import { APIBossConnected } from '@/api/APIBossConnected'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()
const apiBossConnected = new APIBossConnected()

export default {
  name: 'CollaborateurDashboard',
  mixins: [userMixins],
  components: {
    Loading,
  },
  data: function () {
    return {
      // ------------- MISSION -----------
      missions: [],
      missionsRender: [],
      missionsRenderFields: [
        { key: "is_firm", label: "", tdClass: 'ui-helper-center', sortable: true},
        { key: "client_name", label: "Nom du client", tdClass: 'ui-helper-center', sortable: true},
        { key: "name", label: "Intitulé mission", tdClass: 'ui-helper-center', sortable: true},
        { key: "referent", label: "Référent mission", tdClass: 'ui-helper-center' },
      ],
      isAllCollaborateurMissionsLoading: false,

      // --------------- PRIORITIES --------------
      priorities: [],
      nbMajeuresPriorities: 0,
      nbMineuresPriorities: 0,
      prioritiesRender: [],
      prioritiesRenderFields: [
        { key: "name", label: "Intitulé", tdClass: 'ui-helper-center', sortable: true},
        { key: "ending_date", label: "Date d'échéance", tdClass: 'ui-helper-center', sortable: true},
        { key: "referent", label: "Référent", tdClass: 'ui-helper-center' },
        { key: "mission_name", label: "Mission", tdClass: 'ui-helper-center'},
        { key: "priority_state", label: "Etat de la priorité", tdClass: 'ui-helper-center'},
        { key: "lefting_days", label: "Jours restants", tdClass: 'ui-helper-center'},
      ],
      isAllCollaborateurPrioritiesLoading: false,

      // --------- STATISTICS ------------------
      statistics: {
        'nb_collabs': 2,
        'nb_clients': 2,
        'nb_contacts': 2,
        'nb_mission_types': 2,
        'nb_missions': 2,
        'nb_own_priorites': 2,
        'has_bank_coordinates': true,
        'has_logo': true
      },

      isGlobalStatisticsLoading: false,

      stripeSoloProductId: stripeSoloProduct,
      stripeSmallProductId: stripeSmallProduct,
      stripeMediumProductId: stripeMediumProduct,


      // -------- CREATE CONNECTED ACCOUNT -------
      isCreatingConnectedAccount: false,

      isInspectingConnectedAccount: false,
      account: {
        id: '',
        charges_enabled: false
      }
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllCollaborateurMissionsLoading || this.isAllCollaborateurPrioritiesLoading || this.isGlobalStatisticsLoading || this.isCreatingConnectedAccount || this.isInspectingConnectedAccount) {
        return true
      }
      return false
    },
    companyStripeProductId () {
      return this.$store.state.user.companyStripeProductId
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getGlobalStatistics()
    this.inspectAndVerifyConnectedAccount()
    this.connectedCollaborateurAllMissions()
    this.getCollaborateurAllPriorities()
  },
  watch: {
    priorities: function (newPriorities) {
      if (newPriorities.length == 0) {
        this.prioritiesRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newPriorities.length; i++) {
          var daysLeft = this.calculateDaysLeft(newPriorities[i].ending_date)

          if (daysLeft == 'En retard') {
            final_array.push(
              {
                'id': newPriorities[i].id,
                'name': newPriorities[i].name,
                'ending_date': newPriorities[i].ending_date,
                'referent': newPriorities[i].referent_full_name,
                'mission_pk': newPriorities[i].mission,
                'mission_name': newPriorities[i].mission_name,
                'priority_state': newPriorities[i].priority_state,
                'lefting_days': 'En retard',
                '_classes': 'table-danger'
              }
            )
          }
          else {
            final_array.push(
              {
                'id': newPriorities[i].id,
                'name': newPriorities[i].name,
                'ending_date': newPriorities[i].ending_date,
                'referent': newPriorities[i].referent_full_name,
                'mission_pk': newPriorities[i].mission,
                'mission_name': newPriorities[i].mission_name,
                'priority_state': newPriorities[i].priority_state,
                'lefting_days': daysLeft
              }
            )
          }

          if (newPriorities[i].priority_state == 'MAJ') {
            this.nbMajeuresPriorities ++
          }
          else {
            this.nbMineuresPriorities ++
          }
        }
        this.prioritiesRender = final_array
      }
    },

    missions: function (newMissions) {
      if (newMissions.length == 0) {
        this.missionsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newMissions.length; i++) {
          final_array.push(
            {
              'id': newMissions[i].id,
              'is_firm': newMissions[i].client_is_firm,
              'client_name': newMissions[i].client_name,
              'name': newMissions[i].name,
              'referent': newMissions[i].referent_full_name,
            }
          )
        }
        this.missionsRender = final_array
      }
    },
  },
  methods: {
    // -------------------- GLOBAL FUNCTIONS ----------------------------
    calculateDaysLeft(date) {
      var today = this.$dayjs()
      var difference = this.$dayjs(date).diff(today, 'days')
      if (difference < 0) {
        return 'En retard'
      }
      else {
        return String(difference) + ' jours'
      }
    },

    // ----------------- GETTERS ---------------------------------------

    connectedCollaborateurAllMissions () {
      this.isAllCollaborateurMissionsLoading = true
      apiUserConnected.connectedCollaborateurAllMissions(this.token)
      .then((result) => {
        this.missions = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllCollaborateurMissionsLoading = false
      })
    },
    getCollaborateurAllPriorities () {
      this.isAllCollaborateurPrioritiesLoading = true
      apiUserConnected.getCollaborateurAllPriorities(this.token)
      .then((result) => {
        this.priorities = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllCollaborateurPrioritiesLoading = false
      })
    },

    getGlobalStatistics () {
      this.isGlobalStatisticsLoading = true
      apiUserConnected.getGlobalStatistics(this.token)
      .then((result) => {
        this.statistics = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isGlobalStatisticsLoading = false
      })
    },

    inspectAndVerifyConnectedAccount () {
      this.isInspectingConnectedAccount = true
      apiUserConnected.inspectAndVerifyConnectedAccount(this.token)
      .then((result) => {
        this.$store.commit('CONNECTED_ACCOUNT_INSPECTED', result.data)
        this.account = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isInspectingConnectedAccount = false
      })
    },


    // ----- Connected account -------------


    createConnectedAccount () {
      this.isCreatingConnectedAccount = true
      apiBossConnected.createConnectedAccount(this.token)
      .then((result) => {
        window.location.href = result.data.url
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCreatingConnectedAccount = false
      })
    },

    createConnectedAccountOnboardingLink () {
      this.isCreatingConnectedAccount = true
      apiBossConnected.createConnectedAccountOnboardingLink(this.token)
      .then((result) => {
        window.location.href = result.data.url
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCreatingConnectedAccount = false
      })
    },

    getExpressDashboardLoginLink () {
      this.isCreatingConnectedAccount = true
      apiBossConnected.getExpressDashboardLoginLink(this.token)
      .then((result) => {
        window.location.href = result.data.url
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCreatingConnectedAccount = false
      })
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToPriorityPage(item) {
      this.$router.push({ name: 'Modifier votre priorité', params: { mission_pk: item.mission_pk, priority_pk: item.id } })
    },
    goToMissionPage(item) {
      this.$router.push('/mission/' + item.id)
    }
  }

}
</script>
